import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Form, Input, Modal, Spin } from 'antd';
import { getLocalTimezone } from '../../utils/time';
import { internalAxios } from '../../utils/internalAxios';
import S3Upload from '../S3Upload';
import EnquiryEditor from '../EnquiryEditor';

const { confirm } = Modal;

export default function ContactDrawer(props) {
  const { open = false, setOpen, auth, t } = props;
  const { user } = auth;

  const [content, setContent] = useState('');
  const [validateStatus, setValidateStatus] = useState('success');
  const [help, setHelp] = useState();
  const [fileList, setFileList] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTime, setCurrentTime] = useState(getLocalTimezone());

  const onContentChange = (val) => {
    if (val) setContent(val);
    const quillContent = val || content;
    const { length } = quillContent.replace(/<(.|\n)*?>/g, '').trim();
    const needHelp = length < 30 || length > 3000;
    setValidateStatus(needHelp ? 'error' : 'success');
    setHelp(needHelp ? t('contactSupport:enquiryLengthError') : null);
    return needHelp;
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const needHelp = onContentChange();
    if (needHelp) return;

    setSpinning(true);

    await internalAxios
      .sendEnquiry(
        JSON.stringify({
          username: user.username,
          email: user.email,
          content,
          timezone: currentTime,
          attachments: fileList.map((file) => `${file.uid}.${file.extension}`)
        })
      )
      .then(() => setModalVisible(true))
      .catch(() => {});

    setSpinning(false);
  };

  const onOkCancel = () => {
    setContent('');
    setValidateStatus('success');
    setHelp(null);
    setFileList([]);
    setOpen(false);
  };

  const handleCancel = () => {
    confirm({
      title: t('contactSupport:drawer:cancel:title'),
      content: t('contactSupport:drawer:cancel:content'),
      onOk: onOkCancel,
      okCancel() {},
      getContainer: false,
      okText: t('contactSupport:Yes'),
      cancelText: t('contactSupport:No')
    });
  };

  const onModalOk = () => {
    setModalVisible(false);
    onOkCancel();
  };

  setInterval(() => {
    setCurrentTime(getLocalTimezone());
  }, 1000);

  return (
    <Drawer
      title={t('contactSupport:drawer:title')}
      placement="right"
      visible={open}
      onClose={handleCancel}
      className="homepage-right-drawer"
    >
      <Spin spinning={spinning}>
        <Modal
          visible={modalVisible}
          onOk={onModalOk}
          cancelButtonProps={{ style: { display: 'none' } }}
          title={t('contactSupport:drawer:success:title')}
        >
          <span>{t('contactSupport:drawer:success:content')}</span>
        </Modal>
        <Form onSubmit={handleSubmit}>
          <Form.Item label={t('contactSupport:drawer:username')} required>
            <Input defaultValue={user?.username} disabled />
          </Form.Item>
          <Form.Item label={t('contactSupport:drawer:email')} required>
            <Input defaultValue={user.email} disabled />
          </Form.Item>
          <Form.Item label={t('contactSupport:drawer:localTimezone')} required>
            <Input value={currentTime} disabled />
          </Form.Item>
          <Form.Item
            label={t('contactSupport:drawer:enquiry:title')}
            required
            validateStatus={validateStatus}
            help={help}
          >
            <EnquiryEditor content={content} onContentChange={onContentChange} />
          </Form.Item>
          <Form.Item label={t('contactSupport:drawer:enquiry:attachments')}>
            <S3Upload setFileList={setFileList} fileList={fileList} t={t} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t('contactSupport:drawer:enquiry:submit')}
            </Button>
            <Button type="danger" style={{ marginLeft: '1rem' }} onClick={handleCancel}>
              {t('contactSupport:drawer:enquiry:cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

ContactDrawer.propTypes = {
  auth: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
